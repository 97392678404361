/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventBasicDtoFromJSON, EventBasicDtoToJSON, } from './EventBasicDto';
/**
 * Check if a given object implements the EventsDto interface.
 */
export function instanceOfEventsDto(value) {
    if (!('success' in value) || value['success'] === undefined)
        return false;
    if (!('domain' in value) || value['domain'] === undefined)
        return false;
    if (!('events' in value) || value['events'] === undefined)
        return false;
    return true;
}
export function EventsDtoFromJSON(json) {
    return EventsDtoFromJSONTyped(json, false);
}
export function EventsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'success': json['success'],
        'domain': json['domain'],
        'events': (json['events'].map(EventBasicDtoFromJSON)),
    };
}
export function EventsDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'success': value['success'],
        'domain': value['domain'],
        'events': (value['events'].map(EventBasicDtoToJSON)),
    };
}
