/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SongDtoFromJSON, SongDtoToJSON, } from './SongDto';
/**
 * Check if a given object implements the RequestListDto interface.
 */
export function instanceOfRequestListDto(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('songs' in value) || value['songs'] === undefined)
        return false;
    return true;
}
export function RequestListDtoFromJSON(json) {
    return RequestListDtoFromJSONTyped(json, false);
}
export function RequestListDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'songs': (json['songs'].map(SongDtoFromJSON)),
    };
}
export function RequestListDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'songs': (value['songs'].map(SongDtoToJSON)),
    };
}
