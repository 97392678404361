import React, { ReactNode } from 'react';
import { IonIcon, IonSpinner } from '@ionic/react';

interface ProgressButtonProps {
  id: string;
  className: string;
  isLoading: boolean;
  progress?: number;
  icon: string;
  onClick: () => void;
  children: ReactNode;
}

const ProgressButton: React.FC<ProgressButtonProps> = ({
  id,
  className,
  isLoading,
  progress,
  icon,
  onClick,
  children,
}) => {
  return (
    <button
      id={id}
      style={{
        background: isLoading
          ? `linear-gradient(
                to right,
                rgb(18 69 2) 0%,
                rgb(18 69 2) ${progress}%,
                #120b02 ${progress}%,
                #120b02
              )`
          : '#120b02',
      }}
      className={className}
      onClick={onClick}
    >
      {isLoading ? (
        <IonSpinner name="circular" style={{ height: '20px' }}></IonSpinner>
      ) : (
        <IonIcon className="ion-icon" icon={icon} />
      )}
      {children}
    </button>
  );
};

export default ProgressButton;
