import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { iTrack } from '../types/ITrack';
import {
  chLogoFull,
  pdfApple,
  pdfBeatsource,
  pdfCloudCrates,
  pdfDeezer,
  pdfSoundcloud,
  pdfSpotify,
  pdfTidal,
} from '../components/Icons';
import ReactGA from 'react-ga4';

interface ICloudCrateLink {
  name: string;
  link: string;
}

export const exportPdfUtil = (
  title: string,
  trackList: iTrack[],
  hacker: string,
  cloudCrateLinks?: ICloudCrateLink[],
) => {
  console.log('exporting pdf');
  const doc = new jsPDF();

  const logoWidth = 50;
  const logoHeight = 30;

  // Add logo
  if (chLogoFull) {
    const img = new Image();
    img.src = chLogoFull;
    try {
      doc.addImage(img, 'PNG', 15, 10, logoWidth, logoHeight);
    } catch (error) {
      console.error("Couldn't add logo", error);
    } // Adjust position and size
  }

  const titleX = logoWidth + 25;
  const titleY = 25;
  const hackerY = titleY + 10;

  // Add title and author
  doc.setFontSize(25);
  doc.text(title, titleX, titleY);
  doc.setFontSize(14);
  doc.text(`Hacked by: ${hacker}`, titleX, hackerY);

  const cloudCrateLogoWidth = 30;
  const cloudCrateLogoHeight = 15;
  const cloudCrateImageX = 15;
  if (cloudCrateLinks && cloudCrateLinks?.length > 0) {
    // Add logo
    if (pdfCloudCrates) {
      const img = new Image();
      img.src = pdfCloudCrates;
      try {
        doc.addImage(
          img,
          'PNG',
          cloudCrateImageX,
          logoHeight + 12,
          cloudCrateLogoWidth,
          cloudCrateLogoHeight,
        );
      } catch (error) {
        console.error("Couldn't add logo", error);
      }
    }

    cloudCrateLinks.forEach((cloudCrate, index) => {
      const imgX = 20 + cloudCrateLogoWidth + index * 15; // Horizontal position for each image
      let imagePath;
      switch (cloudCrate.name) {
        case 'apple':
          imagePath = pdfApple;
          break;
        case 'beatsource':
          imagePath = pdfBeatsource;
          break;
        case 'deezer':
          imagePath = pdfDeezer;
          break;
        case 'soundcloud':
          imagePath = pdfSoundcloud;
          break;
        case 'spotify':
          imagePath = pdfSpotify;
          break;
        case 'tidal':
          imagePath = pdfTidal;
          break;
      }

      try {
        if (imagePath) {
          doc.addImage(imagePath, 'PNG', imgX, logoHeight + 14, 10, 10);
          doc.link(imgX, logoHeight + 14, 10, 10, { url: cloudCrate.link });
        }
      } catch (error) {
        console.error('Cloud crate link add failed: ', cloudCrate.name);
      }
    });
  }

  // Add song list as table
  const trackRows = trackList.map((track, index) => [
    `${index + 1}`,
    track.Title || '',
    track.Artist || '',
    track.BPM || '',
    track.Key_Camelot || '',
    track.danceability || '',
    track.energy || '',
    track.valence || '',
  ]);

  autoTable(doc, {
    head: [
      ['#', 'Title', 'Artist', 'BPM', 'Key', 'Danceability', 'Energy', 'Mood'],
    ],
    theme: 'striped',
    styles: {
      // cellPadding: 10,
      fillColor: '#e09f24',
    },
    body: trackRows,
    startY: logoHeight + cloudCrateLogoHeight + 12, // Start after the header content
  });

  // Save the PDF
  try {
    doc.save(`${title}.pdf`);
    ReactGA.event({
      category: 'Export',
      action: 'Export Crate',
      label: 'PDF',
      value: 1, // successful
    });
  } catch (error) {
    ReactGA.event({
      category: 'Export',
      action: 'Export Crate',
      label: 'PDF',
      value: 0, // not successful
    });
  }
};
