import { IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { iTrack } from '../../types/ITrack';
import './Tracks.scss';

import './Track.scss';
import { TrackDetailsRow } from './TrackDetailsRow';
import styles from './TrackDragOverlay.module.scss';

/**
 * A display only version of a track, used by the drag-and-drop feature to provide an overlay when moving a track.
 * It does not have any functionality and instead just displays the first row of a track
 */
export const TrackDragOverlay: React.FC<iTrack & { trackNum?: number }> = (
  props,
) => {
  return (
    <div className={`track ${styles.dragOverlay}`}>
      <IonRow class={`${props.unmixable ? 'unmixable' : ''}`}>
        <IonGrid fixed={true}>
          <TrackDetailsRow {...props} editable={true} />
        </IonGrid>
      </IonRow>
    </div>
  );
};
