import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './App.scss';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Providers } from './store/store-provider';
import * as Sentry from '@sentry/react';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.2,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // For finer control of sent transactions you can adjust this value, or
    // use tracesSampler
    tracesSampleRate: 1.0,

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // result in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/api\.cratehackers\.com/],

    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
  });

  const consoleError = console.error;
  console.error = (...args: any[]): void => {
    Sentry.captureException(args);
    consoleError(...args);
  };

  // TODO: Replace with a "/me" API endpoint to get the real user via token
  const email = localStorage.getItem('email');
  if (email) {
    Sentry.setUser({ email: email });
  }
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
// StrictMode will intentionally render twice in Dev Mode. Its by design. https://react.dev/reference/react/StrictMode
root.render(
  <React.StrictMode>
    <Providers>
      <IonApp>
        <IonReactRouter>
          <App />
        </IonReactRouter>
      </IonApp>
    </Providers>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
