import {
  IonRouterOutlet,
  IonSpinner,
  IonSplitPane,
  setupIonicReact,
} from '@ionic/react';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css'; /* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import React, { startTransition, Suspense, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router';
import { Redirect, Route, useHistory } from 'react-router-dom';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Menu from './components/menu/Menu';
import CreateCrate from './pages/create-crate/create-crate';
import Index from './pages/Index';

import GenerateCrate from './pages/myCrates/generate-crate/generate-crate';
import Page from './pages/PageTemplate';

import { useDispatch } from './store/hooks'; // Typed dispatch
import {
  AppIdentifiers,
  getToken,
  setToken,
  unsetToken,
} from './store/slices/token-state.slice';

import {
  CrateControllerSearchRequest,
  GetCratesDto,
  SearchControllerSearchRequest,
} from '@cratehackers/api-client';
import ChangeLog from './components/changeLog/ChangeLog';
import FeatureInProgress from './components/featureInProgress/FeatureInProgress';
import SearchResults from './components/searchResults/SearchResults';
import { default as SpotifyCallback } from './components/spotify/callback';
import Update from './components/update/update';
import { config } from './config';
import BugReportPage from './pages/feedback/bug-report';
import FeatureRequestPage from './pages/feedback/feature-request';
import FolderFlattener from './pages/folder-flattener/folder-flattener';
import Folders from './pages/my-music/Folders';
import Preferences from './pages/preferences/Preferences';
import ApiService from './services/Api.service';
import { RootState } from './store/store';
import { DriveStateSubscriber } from './store/subscribers/drive-state.subscriber';
import './theme/variables.css';
import { iCategory } from './types/ICategory';
import { iCrate } from './types/ICrate';
import { iHacker } from './types/IHackers';
import { iTrack } from './types/ITrack';
import { isTokenValid } from './utils/auth';
import { logout } from './utils/logout';

const Register = React.lazy(() => import('./pages/auth/register/register'));
const LogOut = React.lazy(() => import('./pages/auth/logout/logout'));
const Account = React.lazy(() => import('./pages/account/Account'));
const Login = React.lazy(() => import('./pages/auth/login/login'));
const Crates = React.lazy(() => import('./pages/crates/Crates'));
const Crate = React.lazy(() => import('./pages/crate/Crate'));
const Track = React.lazy(() => import('./pages/track/SingleTrack'));
const myCrates = React.lazy(() => import('./pages/myCrates/myCrates'));
const Charts = React.lazy(() => import('./pages/charts/Charts'));
const Chart = React.lazy(() => import('./pages/charts/Chart'));
const Category = React.lazy(() => import('./pages/category/Category'));
const MyMusic = React.lazy(() => import('./pages/my-music/MyMusic'));
const Marketplace = React.lazy(() => import('./pages/marketplace/Marketplace'));

const Announcement = React.lazy(
  () => import('./pages/announcement/Announcement'),
);
const ForgotPassword = React.lazy(
  () => import('./pages/auth/forgotPassword/forgotPassword'),
);

const ResetPassword = React.lazy(
  () => import('./pages/auth/resetPassword/resetPassword'),
);
const VerifyAccount = React.lazy(
  () => import('./pages/auth/verifyAccount/verifyAccount'),
);

setupIonicReact();

const publicRoutes = () => (
  <Suspense fallback={<IonSpinner name="dots" />}>
    <IonRouterOutlet id="main">
      <Switch>
        <Route path="/register" exact>
          <Register />
        </Route>
        <Route path="/verify/:token" exact>
          <VerifyAccount />
        </Route>
        <Route path="/forgot-password" exact>
          <ForgotPassword />
        </Route>
        <Route path="/reset-password/:token" exact>
          <ResetPassword />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="*">
          <Login />
        </Route>
      </Switch>
    </IonRouterOutlet>
  </Suspense>
);
const privateRoutes = () => (
  <Suspense fallback={<IonSpinner name="dots" />}>
    <IonRouterOutlet id="main">
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/account" component={Account} />
        <Route exact path="/announcement/:url" component={Announcement} />
        <Route exact path="/charts" component={Charts} />
        <Route exact path="/chart/:id" component={Chart} />
        <Route exact path="/crates" component={Crates} />
        <Route exact path="/crate/:id" component={Crate} />
        <Route exact path="/crate/:id/export" component={Crate} />
        <Route exact path="/category/:id" component={Category} />
        <Route exact path="/track" component={Track} />
        <Route exact path="/genres" component={FeatureInProgress} />
        <Route exact path="/hacks" component={FeatureInProgress} />
        <Route exact path="/marketplace" component={Marketplace} />
        <Route exact path="/logout" component={LogOut} />
        <Route exact path="/my-crates" component={myCrates} />
        <Route
          exact
          path="/my-crates/:buildType/:crateId"
          component={GenerateCrate}
        />
        <Route
          exact
          path="/my-crates/crate/:crateId/:buildType"
          component={GenerateCrate}
        />
        <Route
          exact
          path="/my-crates/crate/:crateId"
          component={GenerateCrate}
        />
        <Route path="/my-crates/genre" component={CreateCrate} />
        <Route path="/my-crates/home" component={CreateCrate} />
        <Route path="/my-crates/serato" component={CreateCrate} />
        <Route path="/my-crates/smpl" component={CreateCrate} />
        <Route path="/my-crates/djep" component={CreateCrate} />
        <Route path="/my-crates/spotify" component={CreateCrate} />
        <Route path="/my-crates/text" component={CreateCrate} />
        <Route path="/my-crates/file" component={CreateCrate} />
        <Route path="/my-crates/track" component={CreateCrate} />
        <Route path="/my-crates/djep" component={CreateCrate} />
        <Route exact path="/my-music" component={MyMusic} />
        <Route exact path="/my-music/add-folder" component={Folders} />
        <Route exact path="/page/:name" component={Page} />
        <Route exact path="/preferences" component={Preferences} />
        <Route path="/spotify/callback" component={SpotifyCallback} />
        <Route
          exact
          path="/tools/folder-flattener"
          component={FolderFlattener}
        />
        <Route
          exact
          path="/tools/shazam-to-crate"
          component={FeatureInProgress}
        />
        <Route
          exact
          path="/tools/spotify-import"
          component={FeatureInProgress}
        />
        <Route exact path="/tools/text-import" component={FeatureInProgress} />
        <Route exact path="/vip-crates" component={FeatureInProgress} />
        <Route exact path="/feedback/bug" component={BugReportPage} />
        <Route
          exact
          path="/feedback/feature-request"
          component={FeatureRequestPage}
        />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </IonRouterOutlet>
  </Suspense>
);

const App: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID, {
        // testMode: true, // TODO: For development mode only
      });
    }
  }, []);

  const token = useSelector(
    (state: RootState) => state.tokenState[AppIdentifiers.App],
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const appToken = getToken('app');
    if (appToken && isTokenValid(appToken)) {
      startTransition(() => {
        dispatch(
          setToken(
            AppIdentifiers.App,
            appToken.accessToken,
            appToken.refreshToken,
            appToken.expiration,
          ),
        );

        // TODO: Get user info from API when a token exists.
        const email = localStorage.getItem('email');
        if (!email) {
          logout(history);
        }

        if (ReactGA.isInitialized) {
          const userProperties = {
            app_version: config.VERSION,
            email: '',
          };

          if (email) {
            userProperties.email = email;
          }

          ReactGA.gtag('set', 'user_properties', userProperties);
        }
      });
    } else {
      unsetToken(AppIdentifiers.App);
      logout(history);
    }
  }, [dispatch]);

  const apiClient = ApiService.getApiClient();
  const [searchQuery, setSearchQuery] = useState('');
  const [crateSearchResults, setCrateSearchResults] = useState<iCrate[]>([]);
  const [songSearchResults, setSongSearchResults] = useState<any[]>([]); // TODO: Get SimpleTrackDto
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    if (searchQuery.trim().length < 3) {
      setCrateSearchResults([]);
      setSongSearchResults([]);
      return;
    }

    const requestCrates: CrateControllerSearchRequest = {
      crateSearchDto: {
        term: searchQuery,
        offset: 0,
      },
    };
    const requestSongs: SearchControllerSearchRequest = {
      searchDto: {
        term: searchQuery,
        objectType: 'track',
        offset: 0,
        additionalParams: {
          markets: [],
        },
        raw: false,
        limit: 8,
      },
    };

    setSearchLoading(true);

    apiClient
      .searchControllerSearch(requestSongs)
      .then((results: any) => {
        if (results.items.length > 0) {
          const tracks = results.items.map(
            (track: any): iTrack => ({
              Artist: track?.artist?.name,
              Title: track?.title,
              AlbumCover: track?.album?.picture,
              Key_Camelot: track?.key,
              Key: track?.key,
              PreviewURL: track?.previewUrl,
              BPM: track?.bpm,
              ID: track?.uuid,
              keyMatches: {
                energyBoost: track?.keys?.EnergyBoost,
                energyDrop: track?.keys?.EnergyDrop,
                moodChange: track?.keys?.MoodChange,
                perfectMatch: track?.keys?.PerfectMatch,
              },
            }),
          );
          setSongSearchResults(tracks);
        } else {
          setSongSearchResults([]);
        }
        setSearchLoading(false);
      })
      .catch(() => {
        setSongSearchResults([]);
      });

    apiClient
      .crateControllerSearch(requestCrates)
      .then((data: GetCratesDto) => {
        // Transform GetSimpleCrateDto[] to iCrate[] if necessary
        const crateResults: iCrate[] = data.results.map((crate) => {
          return {
            _id: crate.id, // Ensure you have an `id` field in GetSimpleCrateDto
            Title: crate.title, // Ensure you have a `title` field in GetSimpleCrateDto
            Image: crate.image,
            Hacker: crate.hacker as iHacker[],
            Category: crate.category as iCategory[],
          };
        });

        setCrateSearchResults(crateResults);
        setSearchLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
        // Handle error appropriately, for example:
        setCrateSearchResults([]);
        setSearchLoading(false);
      });
  }, [searchQuery]);

  const handleItemClick = () => {
    setCrateSearchResults([]);
    setSongSearchResults([]);
    setSearchQuery('');
  };

  const handleSearchClose = () => {
    setCrateSearchResults([]);
    setSongSearchResults([]);
    setSearchQuery('');
  };

  return (
    <>
      <Update />
      <DriveStateSubscriber />
      {token.accessToken ? (
        <>
          <Header searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          <IonSplitPane when="xl" contentId="main">
            <Menu />
            <ChangeLog />
            {searchQuery.trim().length > 0 && (
              <SearchResults
                crateData={crateSearchResults}
                songData={songSearchResults}
                handleItemClicked={handleItemClick}
                searchLoading={searchLoading}
                handleClose={handleSearchClose}
              />
            )}
            {privateRoutes()}
          </IonSplitPane>
          <Footer />
        </>
      ) : (
        <>{publicRoutes()}</>
      )}
    </>
  );
};
export default App;
