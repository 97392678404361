// app.tsx or your root component
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setConnectedDrives } from '../slices/drive-state.slice';

interface DriveInfo {
  path: string;
  label: string;
  isNetwork: boolean;
}

export const DriveStateSubscriber: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Set up the IPC listener
    const handleDrivesUpdated = (driveInfo: DriveInfo[]) => {
      console.log('handleDrivesUpdated', driveInfo);
      dispatch(setConnectedDrives(driveInfo));
    };

    // Subscribe to the IPC message
    window.api?.on('drive-info', handleDrivesUpdated);

    // Cleanup on unmount
    return () => {
      // window.api?.removeListener('drive-info', handleDrivesUpdated);
    };
  }, [dispatch]);

  return null; // This component doesn't render anything
};
