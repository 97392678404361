import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import categoriesReducer from './slices/categories.slice';
import { CrateSlice } from './slices/crate.slice';
import { DriveStateSlice } from './slices/drive-state.slice';
import { ThemeStateSlice } from './slices/theme-state.slice';
import { TokenStateSlice } from './slices/token-state.slice';
import { AudioPlayerSlice } from './slices/audio-player-state.slice';
import { TrackSlice } from './slices/track.slice';
import { trackMatcherSlice } from './slices/track-matcher.slice';
import djepTitleFormatSlice from './slices/djep-title-format.slice';

const store = configureStore({
  reducer: {
    themeState: ThemeStateSlice.reducer,
    tokenState: TokenStateSlice.reducer,
    driveState: DriveStateSlice.reducer,
    categories: categoriesReducer,
    crate: CrateSlice.reducer,
    track: TrackSlice.reducer,
    audioPlayerState: AudioPlayerSlice.reducer,
    trackMatcherState: trackMatcherSlice.reducer,
    djepTitleFormat: djepTitleFormatSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
