import { IonCol, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';
import { reorderThreeOutline, trashBinOutline } from 'ionicons/icons';
import React from 'react';
import { iTrack } from '../../types/ITrack';
import Waveform from '../waveform/Waveform';
import './Tracks.scss';

import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import './Track.scss';

export const TrackDetailsRow: React.FC<
  iTrack & {
    trackNum?: number;
    editable?: boolean;
    dragListeners?: SyntheticListenerMap;
    onDeleteTrack?: (
      event: React.MouseEvent<HTMLIonIconElement, MouseEvent>,
    ) => void;
  }
> = ({
  Artist,
  Title,
  AlbumCover,
  BPM,
  energy,
  danceability,
  Key_Camelot,
  valence,
  PreviewURL,
  trackNum,
  dragListeners,
  editable,
  onDeleteTrack,
}) => {
  return (
    <IonRow className="track-details">
      <IonCol size="12" sizeMd="6" sizeLg="5">
        <IonGrid>
          <IonRow>
            <IonCol size="auto" className="track-number-container">
              <div {...dragListeners}>
                {editable && (
                  <IonIcon
                    id="drag-handle"
                    color="primary"
                    icon={reorderThreeOutline}
                  />
                )}
              </div>
              <div className="track-circle">
                <div
                  className="track-number"
                  style={{ '--track-num': '"' + trackNum + '"' } as any}
                  color="dark"
                ></div>
              </div>
              {/*TODO: Add deleting to admin view eventually*/}
              {editable && (
                <div className="track-delete">
                  <IonIcon
                    className="track-delete-icon"
                    color="primary"
                    icon={trashBinOutline}
                    onClick={onDeleteTrack}
                  />
                </div>
              )}
            </IonCol>
            {AlbumCover && (
              <IonCol size="auto">
                <img
                  className="album-cover"
                  src={AlbumCover}
                  alt={'album cover for track'}
                />
              </IonCol>
            )}
            <IonCol>
              <IonRow className="song-details-container">
                <IonText color="dark">
                  <h1>{Title}</h1>
                </IonText>
              </IonRow>
              <IonRow className="song-details-container">
                <IonText color="medium">
                  <p>{Artist}</p>
                </IonText>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCol>

      <IonCol size="12" sizeMd="6" sizeLg="4">
        <IonRow className="main-audio-feature-row">
          {Key_Camelot !== undefined && Key_Camelot !== '' && (
            <IonCol>
              <IonText
                className="track-data-spec-key audio-feature-container"
                color="primary"
              >
                Key
              </IonText>
              <div className="audio-feature-container">{Key_Camelot}</div>
            </IonCol>
          )}
          {BPM !== undefined && BPM !== 0 && (
            <IonCol>
              <IonText
                className="track-data-spec-key audio-feature-container"
                color="primary"
              >
                BPM
              </IonText>
              <div className="audio-feature-container">{BPM}</div>
            </IonCol>
          )}
          {energy !== undefined && energy !== 0 && (
            <IonCol>
              <IonText
                className="track-data-spec-key audio-feature-container"
                color="primary"
              >
                Energy
              </IonText>
              <div className="audio-feature-container">{energy}</div>
            </IonCol>
          )}
          {danceability !== undefined && danceability !== 0 && (
            <IonCol>
              <IonText
                className="track-data-spec-key audio-feature-container"
                color="primary"
              >
                Dance
              </IonText>
              <div className="audio-feature-container">{danceability}</div>
            </IonCol>
          )}
          <IonCol>
            {valence !== undefined && valence !== 0 && (
              <>
                <IonText
                  className="track-data-spec-key audio-feature-container"
                  color="primary"
                >
                  Mood
                </IonText>
                <div className="audio-feature-container">{valence}</div>
              </>
            )}
          </IonCol>
        </IonRow>
      </IonCol>
      <IonCol size="12" sizeLg="3">
        {PreviewURL ? (
          <div className="center-content preview-container">
            <IonCol className="waveform-preview">
              <Waveform height={35} url={PreviewURL} />
            </IonCol>
          </div>
        ) : (
          <div className="no-preview-text">
            <IonText color="medium">No Preview Available</IonText>
          </div>
        )}
      </IonCol>
    </IonRow>
  );
};
