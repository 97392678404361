import { iTrack, iTracks } from '../types/ITrack';
import {
  AppIdentifiers,
  getToken,
  setToken,
} from '../store/slices/token-state.slice';
import store from '../store/store';
import isElectron from '../utils/isElectron';
import { generateRandomString } from '../utils/generateRandomString';
import { base64encode, sha256 } from '../utils/cryptographicFunctions';
import RequestService from './Request.service';
import { config } from '../config';
import ApiService from './Api.service';
import * as dash from 'lodash';
import ReactGA from 'react-ga4';

export default class SpotifyService {
  /* App Configuration */
  private static REDIRECT_URI_ELECTRON = 'cratehackersx://spotify/callback';
  private static REDIRECT_URI_WEB = config.BASE_URL + '/spotify/callback';

  /* CrateHackers API Configuration */
  private static appApiUrl =
    config.API_BASE_URL ?? 'https://api.cratehackers.com/'; // TODO: Replace with backend SDK

  /* Spotify API Configuration */
  private static CLIENT_ID = config.SPOTIFY_CLIENT_ID || '';
  private static SCOPES = [
    'user-read-private',
    'user-read-email',
    'playlist-modify-public',
    'playlist-modify-private',
  ];
  private static apiUrl = 'https://api.spotify.com/v1';
  private static accountUrl = 'https://accounts.spotify.com';

  // Data structure that manages the current active token, caching it in localStorage
  public static getAccessToken = () => {
    return getToken(AppIdentifiers.Spotify).accessToken;
  };

  public static getRefreshToken() {
    return getToken(AppIdentifiers.Spotify).refreshToken; // TODO: Refactor token manager to support refresh and refresh_in values
  }

  public static getTokenExpiration() {
    return getToken(AppIdentifiers.Spotify).expiration;
  }

  public static getAuthCode() {
    const args = new URLSearchParams(window.location.search);
    return args.get('code');
  }

  public static async getToken(code: string) {
    const code_verifier = localStorage.getItem('spotify_code_verifier') ?? '';

    const redirectUri = isElectron()
      ? this.REDIRECT_URI_ELECTRON
      : this.REDIRECT_URI_WEB;

    const response = await fetch(this.accountUrl + '/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: this.CLIENT_ID,
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: redirectUri,
        code_verifier: code_verifier,
      }),
    });

    localStorage.removeItem('spotify_code_verifier');

    return await response.json();
  }

  public static async refreshToken(forced?: boolean) {
    const tokenExpiration = this.getTokenExpiration() ?? 0;
    const refreshToken = this.getRefreshToken();
    if ((!forced && tokenExpiration > Date.now()) || !refreshToken) {
      return;
    }

    const response = await fetch(this.accountUrl + '/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: this.CLIENT_ID,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }),
    });

    // TODO: Replace existing tokens with new ones
    const responseJson = await response.json();

    if (responseJson.access_token) {
      console.log('expires_in', responseJson);
      store.dispatch(
        setToken(
          AppIdentifiers.Spotify,
          responseJson.access_token,
          responseJson.refresh_token,
          Date.now() + Number(responseJson.expires_in) * 1000,
        ),
      );
    }

    return response;
  }

  public static async getSpotifyAuthorizeUrl(internalRedirectUri: string) {
    const codeVerifier = generateRandomString(128);
    const hashed = await sha256(codeVerifier);
    const codeChallenge = base64encode(hashed);

    const redirectUri = isElectron()
      ? this.REDIRECT_URI_ELECTRON
      : this.REDIRECT_URI_WEB;

    window.localStorage.setItem('spotify_code_verifier', codeVerifier);
    window.localStorage.setItem('spotify_redirect', internalRedirectUri);

    const authUrl = new URL(this.accountUrl + '/authorize');
    const params = {
      response_type: 'code',
      client_id: this.CLIENT_ID,
      scope: this.SCOPES.join(' '),
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
      redirect_uri: redirectUri, // TODO: Add internal redirect that callback can handle
    };

    authUrl.search = new URLSearchParams(params).toString();
    return authUrl.toString(); // Redirect the user to the authorization server for login
  }

  public static async getProfile() {
    await this.refreshToken();

    const response = await fetch(this.apiUrl + '/me', {
      headers: {
        Authorization: `Bearer ${getToken('spotify').accessToken}`,
      },
    });

    return await response.json();
  }

  public static async SearchTracks(query: string): Promise<iTracks> {
    await this.refreshToken();

    let options;
    const tokenExpiration = getToken('spotify').accessToken ?? null;
    if (tokenExpiration) {
      options = {
        headers: {
          Authorization: `Bearer ${getToken('spotify').accessToken}`,
        },
      };
    }

    const response = await fetch(
      `${this.apiUrl}/search?q=${query}&type=track`,
      options,
    );

    return await this.mapSpotifyResponse(await response.json(), true);
  }

  private static async createPlaylist(name: string): Promise<any> {
    const userId = (await this.getProfile()).id;
    return await this.callApi(`/users/${userId}/playlists`, 'POST', {
      name: name,
      description: `Playlist created by CrateHackers from crate ${name}`,
      public: false,
    });
  }

  private static async addTrackToPlaylist(
    playlistId: string,
    trackId: string,
  ): Promise<boolean> {
    const response = await this.callApi(
      `/playlists/${playlistId}/tracks`,
      'POST',
      {
        uris: [`spotify:track:${trackId}`],
      },
    );
    return response.snapshot_id !== undefined;
  }

  public static async ExportCrateToSpotify(
    crateName: string,
    tracks: iTrack[],
  ): Promise<{
    success: boolean;
    successTracks: iTrack[];
    failedTracks: iTrack[];
  }> {
    const result = {
      success: false,
      successTracks: [] as iTrack[],
      failedTracks: [] as iTrack[],
    };
    const playlistData = await this.createPlaylist(crateName);
    const playlistId = playlistData.id;

    let trackURIs: string[] = [];

    const trackChunks = dash.chunk(tracks, 100); // split the tracks array into chunks of 100
    for (const chunk of trackChunks) {
      for (const track of chunk) {
        let newTitle = '';
        if (track.Title) {
          const match = track.Title.match(/^[^(\[{-]+/);
          newTitle = match ? match[0].trim() : track.Title.trim();
        }
        const searchResult = await this.SearchTracks(
          `${track.Artist} ${newTitle}`,
        );
        if (searchResult?.tracks && searchResult.tracks.length > 0) {
          trackURIs.push(`spotify:track:${searchResult.tracks[0].ID}`);
          result.successTracks.push(track);
        } else {
          result.failedTracks.push(track);
        }
      }

      // Add the accumulated URIs to the playlist
      const response = await this.callApi(
        `/playlists/${playlistId}/tracks`,
        'POST',
        {
          uris: trackURIs,
        },
      );

      // Reset the array for the next iteration
      trackURIs = [];
    }

    if (result.failedTracks.length === 0) {
      result.success = true;
      ReactGA.event({
        category: 'Export',
        action: 'Export Crate',
        label: 'spotify',
        value: 1, // successful
      });
    } else {
      ReactGA.event({
        category: 'Export',
        action: 'Export Crate',
        label: 'spotify',
        value: 0, // successful
      });
    }
    return result;
  }

  public static async GetRecommendations(
    track: iTrack,
  ): Promise<{ tracks: any; info: any }> {
    const filters = `${
      track.ArtistID ? `seed_artist=${track.ArtistID.split(',')[0]}&` : ''
    }seed_tracks=${track.ID}`;

    await this.refreshToken();

    let options;
    const tokenExpiration = getToken('spotify').accessToken ?? null;
    if (tokenExpiration) {
      options = {
        headers: {
          Authorization: `Bearer ${getToken('spotify').accessToken}`,
        },
      };
    }

    const response = await fetch(
      `${this.apiUrl}/recommendations?limit=20&${filters}`,
      options,
    );

    const resData = await response.json();
    const tracks: any = await this.mapSpotifyResponse(resData);

    return {
      tracks,
      info: resData.seeds[0],
    };
  }

  public static async GetPlaylistById(playlistId: string) {
    await this.refreshToken();

    let response: any;

    if (this.getAccessToken()) {
      response = await this.callApi(`/playlists/${playlistId}`);
    } else {
      response = await this.callAppApi(`/spotify/playlist/get`, 'POST', {
        playlist_id: playlistId,
      });
    }

    const tracks: any = await this.mapSpotifyResponse(response);

    return {
      tracks,
      info: response,
    };
  }

  public static async GetAlbumById(albumId: string) {
    await this.refreshToken();

    let response: any;

    if (this.getAccessToken()) {
      response = await this.callApi(`/albums/${albumId}`);
    } else {
      response = await this.callAppApi(`/spotify/album/get`, 'POST', {
        album_id: albumId,
      });
    }

    const tracks: any = await this.mapSpotifyResponse(response);

    return {
      tracks,
      info: response,
    };
  }

  private static async callAppApi(url: string, method: string, body: any) {
    console.log('callAppApi url:', url);
    console.log('callAppApi method:', method);

    const accessToken = this.getAccessToken() ?? null;
    if (accessToken) {
      if (typeof body.token === 'undefined') {
        body.token = getToken(AppIdentifiers.Spotify);
        body.token.token_type = 'Bearer';
      }
    }

    console.log('callAppApi body:', body);

    url = this.appApiUrl + url;
    return await RequestService.fetch({ url, method, body });
  }

  private static async callApi(url: string, method = 'GET', body?: any) {
    const options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: '', // add necessary property
      } as { 'Content-Type': string; Authorization?: string },
      body: this.buildRequestBody(body),
    };
    const accessToken = this.getAccessToken() ?? null;
    if (accessToken) {
      if (!options.headers.Authorization) {
        options.headers.Authorization = `Bearer ${this.getAccessToken()}`;
      }
    }
    const response = await fetch(this.apiUrl + url, options);
    // If Unauthorized, try refreshing token or re-authenticating
    if (response.ok) {
      return response.json();
    } else if (response.status === 401) {
      await this.refreshToken();
      const newAccessToken = this.getAccessToken() ?? null;
      if (newAccessToken) {
        options.headers.Authorization = `Bearer ${newAccessToken}`;
        const newResponse = await fetch(this.apiUrl + url, options);
        if (newResponse.ok) {
          return newResponse.json();
        } else {
          throw new Error('Reauthorization needed.');
        }
      } else {
        throw new Error('Reauthorization needed.');
      }
    } else {
      throw new Error('Network response was not ok');
    }
  }

  private static buildRequestBody(data: any): BodyInit | null {
    if (data === null || data === undefined) {
      return null;
    }

    return JSON.stringify(data);
  }

  private static async mapSpotifyResponse(
    response: any,
    simple = false,
  ): Promise<iTracks> {
    if (!response.tracks) {
      return { tracks: [] };
    }

    // console.log('mapSpotifyResponse', response);
    const chAPI = ApiService.getApiClient();
    const items = response.tracks?.items || response.tracks;
    const trackItems = await Promise.all(
      items.map(async (item: any, index: number) => {
        const itm = item.track || item;

        let hydratedItem;
        if (!simple) {
          console.log('Getting hydrated');
          hydratedItem = await chAPI.trackControllerGetTrack({
            id: itm.id,
          });
        } else {
          hydratedItem = null;
        }
        const albumCover =
          itm.album?.images[0]?.url ||
          response?.images?.[0]?.url ||
          itm.images?.[0]?.url ||
          'defaultImageUrl';

        return {
          ID: itm.id,
          Artist: itm.artists.map((artist: any) => artist.name).join(', '),
          Title: itm.name,
          SpotifyID: itm.id,
          Preview: itm.preview_url,
          AlbumCover: albumCover,
          duration: SpotifyService.formatDuration(itm.duration_ms),
          energy:
            hydratedItem &&
            hydratedItem?.energy &&
            Math.round(hydratedItem.energy * 100),
          danceability:
            hydratedItem &&
            hydratedItem?.danceability &&
            Math.round(hydratedItem.danceability * 100),
          valence:
            hydratedItem &&
            hydratedItem?.valence &&
            Math.round(hydratedItem.valence * 100),
          popularity: hydratedItem && hydratedItem.popularity,
          Key_Camelot: hydratedItem && hydratedItem.key,
          BPM:
            hydratedItem && hydratedItem?.bpm && Math.round(hydratedItem.bpm),
        };
      }),
    );
    return { tracks: trackItems };
  }

  private static formatDuration(durationMs: number): string {
    const minutes = Math.floor(durationMs / 60000);
    const seconds = ((durationMs % 60000) / 1000).toFixed(0);
    return minutes + ':' + (parseInt(seconds) < 10 ? '0' : '') + seconds;
  }
}
