import { IonCol, IonLabel, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import BuildItemSelect from '../../build-item-select/build-item-select';
import './serato.scss';
import { on, removeListener, run } from '../../../../helper/electron';
import { iTrack } from '../../../../types/ITrack';
import BuildGenerateCrate from '../../build-generate-crate/build-generate-crate';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { GridReadyEvent } from 'ag-grid-community';

interface ImportHistoryProps {
  setCrateDescription: (description: string) => void;
  setTrackList: (trackList: iTrack[]) => void;
}

type TrackType = {
  filepath: string;
  artist: string;
  title: string;
  bpm: number;
  key: string;
};

const ImportHistory: React.FC<ImportHistoryProps> = ({
  setCrateDescription,
  setTrackList,
}) => {
  const theme = useSelector((state: RootState) => state.themeState.theme);
  const [selectedHistory, setSelectedHistory] = useState<any[]>([]);
  const [historySessions, setHistorySessions] = useState([]);
  const [tracks, setTracks] = useState<TrackType[]>([]);
  const [gridApi, setGridApi] = useState<any>();

  const getSeratoHistoryTracks = (filename: string): Promise<any[]> => {
    return new Promise((resolve) => {
      on('serato/get-history-tracks', (data) => {
        removeListener('serato/get-history-tracks');
        resolve(data);
      });

      run('serato/get-history-tracks', filename);
    });
  };

  // TODO: Get everything on the same page for casing so we don't have to do this
  const hackyTransform = async (inputData: any[]): Promise<iTrack[]> => {
    // Get all of the tracks in order
    inputData.sort((a, b) => a.updated_at - b.updated_at);

    return inputData
      .filter((data) => data !== undefined)
      .map((data, index) => {
        return {
          ID: '', // This is placeholder, these tracks don't have an id
          Artist: data.artist ? data.artist : '',
          Title: data.title ? data.title : '',
          Preview: data.filepath ? data.filepath : '',
          // TODO: Try to get down to one of these definitions
          // PreviewURL: data.filepath ? data.filepath : '',
          duration: 0,
          BPM: data.bpm ? data.bpm : 0,
          energy: 0,
          danceability: 0,
          valence: 0,
          Key: 0, // TODO: Figure out what number bubble wants --> data.key ? data.key : '',
          // TODO: Probably transform key on api side
          // ArtistID: '',
          // AlbumCover: '',
          // PreviewURL: '',
          // SpotifyID: '',
        };
      });
  };

  // The timestamp has been put into the name

  async function handleSelectedHistoryChange(selectedSessions: any[]) {
    selectedHistory.sort(
      (a, b) => new Date(a.name).getTime() - new Date(b.name).getTime(),
    );
    setSelectedHistory(selectedSessions);

    const allSessionTracks = [];
    for (const history of selectedSessions) {
      const sessionTracks = await getSeratoHistoryTracks(history.filename);
      console.log('history tracks:', sessionTracks);
      allSessionTracks.push(...sessionTracks);
    }
    setTracks(allSessionTracks);
    setTrackList(await hackyTransform(allSessionTracks));

    const historyExportNameString =
      Array.isArray(selectedHistory) && selectedHistory.length
        ? `${selectedHistory[0].name}${
            selectedHistory.length > 1
              ? `-${selectedHistory[selectedHistory.length - 1].name}`
              : ''
          }`
        : '';

    setCrateDescription(historyExportNameString);
  }

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const [columnDefs] = useState([
    { field: 'id', hide: true },
    {
      field: 'artist',
      headerName: 'Artist',
      flex: 1,
      resizable: true,
      filter: false,
      sortable: true,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      resizable: true,
      filter: false,
      sortable: true,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
    {
      field: 'bpm',
      headerName: 'BPM',
      width: 70,
      resizable: true,
      filter: false,
      sortable: true,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
    {
      field: 'key',
      headerName: 'Key',
      width: 70,
      resizable: true,
      filter: false,
      sortable: true,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
  ]);

  useEffect(() => {
    on('serato/get-history-sessions', (data) => {
      setHistorySessions(data);
    });
    run('serato/get-history-sessions');

    return () => {
      removeListener('serato/get-history-sessions');
    };
  }, []);

  return (
    <>
      <div className="serato-history-ctn">
        <IonRow>
          <IonCol size="12" sizeMd="6">
            {history && (
              <BuildItemSelect
                items={historySessions}
                onSelectedItemsChange={handleSelectedHistoryChange}
                selectionType="checkbox"
              />
            )}
          </IonCol>
          <IonCol size="12" sizeMd="6">
            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: '100%' }}
            >
              <AgGridReact
                className={`ag-theme-alpine${theme === 'dark' ? '-dark' : ''}`}
                rowData={tracks}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
              />
            </div>
          </IonCol>
        </IonRow>
      </div>
    </>
  );
};

export default ImportHistory;
