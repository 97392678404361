import sha256 from 'crypto-js/sha256';
import { iTrack } from '../types/ITrack';

/**
 * Generates a track ID for a given track.
 * If one already exists, that is simply returned, otherwise it is a hash of the title and artist
 */
export const generateTrackID = (track: iTrack): string =>
  track.ID ||
  sha256(
    JSON.stringify({
      artist: track.Artist,
      title: track.Title,
    }),
  ).toString();
