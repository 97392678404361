/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CHChartDtoFromJSON, CHChartDtoToJSON, } from './CHChartDto';
/**
 * Check if a given object implements the CHChartsDto interface.
 */
export function instanceOfCHChartsDto(value) {
    if (!('count' in value) || value['count'] === undefined)
        return false;
    if (!('remaining' in value) || value['remaining'] === undefined)
        return false;
    if (!('cursor' in value) || value['cursor'] === undefined)
        return false;
    if (!('results' in value) || value['results'] === undefined)
        return false;
    return true;
}
export function CHChartsDtoFromJSON(json) {
    return CHChartsDtoFromJSONTyped(json, false);
}
export function CHChartsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'count': json['count'],
        'remaining': json['remaining'],
        'cursor': json['cursor'],
        'results': (json['results'].map(CHChartDtoFromJSON)),
    };
}
export function CHChartsDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'count': value['count'],
        'remaining': value['remaining'],
        'cursor': value['cursor'],
        'results': (value['results'].map(CHChartDtoToJSON)),
    };
}
