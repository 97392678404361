import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import TreeView, { INode } from 'react-accessible-treeview';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../../../services/Api.service';
import {
  EventBasicDto,
  IntegrationDto,
  IntegrationStatusDto,
  SongDto,
} from '@cratehackers/api-client';
import './djep.scss';
import buildStyle from '../build-path.module.scss';
import BuildGenerateCrate from '../../build-generate-crate/build-generate-crate';
import { iCrate } from '../../../../types/ICrate';
import { iTrack } from '../../../../types/ITrack';
import { openExternalLink } from '../../../../utils/openExternalLink';
import { caretDownOutline, caretForwardOutline } from 'ionicons/icons';

const DjepPath: React.FC = () => {
  const [isBtnDisabled, setBtnDisabled] = useState(true);
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [checkingIntegration, setCheckingIntegration] = useState(false);
  const [events, setEvents] = useState<EventBasicDto[]>([]);
  const [eventTree, setEventTree] = useState<INode[]>([]);
  const [selectedTracks, setSelectedTracks] = useState<SongDto[]>([]);
  const [folderTracksMap, setFolderTracksMap] = useState<
    Record<number, SongDto[]>
  >({});
  const [fetchedEventIds, setFetchedEventIds] = useState<Set<number>>(
    new Set(),
  );
  const [apiKey, setApiKey] = useState('');
  const [apiSecretKey, setApiSecretKey] = useState('');
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [selectedNode, setSelectedNode] = useState<INode | null>(null);
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState(
    () => new Date().toISOString().split('T')[0],
  );
  const [endDate, setEndDate] = useState(() => {
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
    return oneMonthFromNow.toISOString().split('T')[0];
  });

  const generateNumericId = (base: number, offset = 0) => base * 1000 + offset;

  const findEventName = (): string => {
    let currentNode = eventTree.find((node) => node.id === selectedNode?.id);
    while (currentNode && currentNode.parent !== 0) {
      currentNode = eventTree.find((node) => node.id === currentNode?.parent);
    }
    return currentNode?.metadata?.rawName
      ? String(currentNode.metadata.rawName)
      : 'Unknown Event';
  };

  const generateTitle = () => {
    if (selectedNode?.parent === 0) {
      return `${findEventName()} - All Requests`;
    }
    return `${findEventName()} - ${selectedNode?.metadata?.rawName}`;
  };

  const generateDescription = () => {
    return `${generateTitle()} - Generated from DJ Event Planner Import`;
  };

  const mapTracks = (): iTrack[] => {
    return selectedTracks.map((track) => {
      return {
        ID: '',
        Artist: track.artist,
        Title: track.title,
      };
    });
  };

  const buildCrate = () => {
    const newCrate: iCrate = {
      _id: '',
      Title: generateTitle(),
      Description: generateDescription(),
      Tracks: mapTracks(),
      createdFrom: 'DJEP',
    };

    return newCrate;
  };
  const updateTreeData = (
    list: INode[],
    id: number,
    children: INode[],
  ): INode[] => {
    const data = list.map((node) => {
      if (node.id === id) {
        return { ...node, children: children.map((child) => child.id) };
      }
      return node;
    });

    const existingIds = new Set(data.map((node) => node.id));
    const newNodes = children.filter((child) => !existingIds.has(child.id));

    return [...data, ...newNodes];
  };

  const fetchEvents = async () => {
    setLoadingEvents(true);
    const api = ApiService.getApiClient();
    try {
      const response = await api.djepControllerGetEvents({
        getEventsDto: {
          startDate: startDate.replace(/-/g, '/'),
          endDate: endDate.replace(/-/g, '/'),
        },
      });
      setEvents(response.events || []);
    } catch (err) {
      console.error('Failed to fetch events:', err);
    } finally {
      setLoadingEvents(false);
    }
  };

  const buildInitialTree = () => {
    const rootNode: INode = {
      id: 0,
      name: 'All Events',
      parent: null,
      children: [],
    };

    const eventNodes = events.map((event, index) => {
      const eventName: string = [
        event.eventDate || '',
        event.clientName || '',
        event.eventTitle || '',
      ]
        .filter(Boolean) // Remove any empty values
        .join(' ');

      return {
        id: generateNumericId(index + 1),
        name: eventName || 'Untitled Event',
        parent: 0,
        children: [],
        isBranch: true,
        metadata: {
          rawName: `${eventName}`,
        },
      };
    });

    rootNode.children = eventNodes.map((node) => node.id);
    setEventTree([rootNode, ...eventNodes]);
  };

  // TODO: Move these things into a service eventually
  const fetchEventDetails = async (eventId: string, nodeId: number) => {
    if (fetchedEventIds.has(nodeId)) return;

    const api = ApiService.getApiClient();
    try {
      const response = await api.djepControllerGetEvent({ id: eventId });
      const folders = response.folders || [];
      const allRequestListNodes: INode[] = [];
      const allEventSongs: SongDto[] = [];

      if (folders.length === 0) {
        const noRequestNode: INode = {
          id: generateNumericId(nodeId, 999),
          name: 'No Requests',
          parent: nodeId,
          children: [],
          isBranch: false,
        };
        setEventTree((prevTree) =>
          updateTreeData(prevTree, nodeId, [noRequestNode]),
        );
        return;
      }

      const folderNodes = folders.map((folder, folderIndex) => {
        const folderId = generateNumericId(nodeId, folderIndex + 1);

        if (folder.name === 'Guest Requests') {
          const guestRequestSongs = folder.requestLists.flatMap(
            (rl) => rl.songs.filter((song) => song.title || song.artist), // Filter out invalid songs
          );
          allEventSongs.push(...guestRequestSongs);

          setFolderTracksMap((prev) => ({
            ...prev,
            [folderId]: guestRequestSongs,
          }));

          return {
            id: folderId,
            name: `Guest Requests (${guestRequestSongs.length} songs)`,
            parent: nodeId,
            children: [], // No child nodes for Guest Requests
            isBranch: false,
            metadata: { rawName: `${folder.name}` },
          };
        }

        // Combine request lists with the same name
        const combinedRequestLists = folder.requestLists.reduce(
          (acc: Record<string, SongDto[]>, requestList) => {
            const filteredSongs = requestList.songs.filter(
              (song) => song.title || song.artist, // Filter out invalid songs
            );
            if (!acc[requestList.name]) {
              acc[requestList.name] = [];
            }
            acc[requestList.name].push(...filteredSongs);
            return acc;
          },
          {},
        );

        const requestListNodes: INode[] = Object.entries(
          combinedRequestLists,
        ).map(([name, songs], rlIndex) => {
          const requestListId = generateNumericId(folderId, rlIndex + 1);

          setFolderTracksMap((prev) => ({
            ...prev,
            [requestListId]: songs,
          }));
          allEventSongs.push(...songs);

          return {
            id: requestListId,
            name: `${name} (${songs.length} songs)`,
            parent: folderId,
            children: [],
            isBranch: false,
            metadata: { rawName: `${name}` },
          };
        });

        allRequestListNodes.push(...requestListNodes);

        const folderSongs = folder.requestLists.flatMap(
          (rl) => rl.songs.filter((song) => song.title || song.artist), // Filter out invalid songs
        );
        allEventSongs.push(...folderSongs);

        setFolderTracksMap((prev) => ({
          ...prev,
          [folderId]: folderSongs,
        }));

        return {
          id: folderId,
          name: `${folder.name} (${folderSongs.length} songs)`,
          parent: nodeId,
          children: requestListNodes.map((node) => node.id),
          isBranch: true,
          metadata: { rawName: `${folder.name}` },
        };
      });

      setFolderTracksMap((prev) => ({
        ...prev,
        [nodeId]: allEventSongs,
      }));

      setSelectedTracks(allEventSongs);

      setEventTree((prevTree) =>
        updateTreeData(prevTree, nodeId, folderNodes).concat(
          allRequestListNodes,
        ),
      );
      setFetchedEventIds((prev) => new Set(prev).add(nodeId));
    } catch (error) {
      console.error(`Failed to fetch event details for ${eventId}:`, error);
    }
  };

  const checkIntegration = async () => {
    setCheckingIntegration(true);
    const api = ApiService.getApiClient();
    try {
      const integrationStatus: IntegrationStatusDto =
        await api.djepControllerCheckIntegraion();
      if (integrationStatus.status === 'active') {
        setIsIntegrated(true);
        setError('');
        await fetchEvents();
      } else if (
        integrationStatus.status.includes('inactive') ||
        integrationStatus.status.includes('expired') // TODO: DJEP was sending me garbage characters with it
      ) {
        setIsIntegrated(false);
        setError(`Your API key and secret are expired or invalid`);
      } else {
        setIsIntegrated(false);
        setError(`Your API key and secret are invalid`);
      }
      setCheckingIntegration(false);
    } catch (error) {
      console.error(error);
      setCheckingIntegration(false);
      setIsIntegrated(false);
    }
  };

  const handleIntegrate = async () => {
    setCheckingIntegration(true);
    const api = ApiService.getApiClient();
    const integrationDto: IntegrationDto = { apiKey, apiSecretKey };
    try {
      await api.djepControllerIntegrate({ integrationDto });
      await checkIntegration();
      await fetchEvents();
    } catch (err) {
      console.error('Integration failed:', err);
      setError('Integration failed. Check your api key and secret');
    } finally {
      setCheckingIntegration(false);
    }
  };

  const handleLoadData = async ({ element }: { element: INode }) => {
    if (!element.isBranch) return;

    const eventId = events.find(
      (event, index) => generateNumericId(index + 1) === Number(element.id),
    )?.eventId;

    if (eventId) {
      await fetchEventDetails(eventId, Number(element.id));
    }
  };

  const handleNodeSelected = (element: INode) => {
    setSelectedNode(element);
    handleSelectTracks(element);
  };

  const handleSelectTracks = (element: INode) => {
    setSelectedTracks(folderTracksMap[Number(element.id)] || []);
  };

  const handleRemoveIntegration = async () => {
    const api = ApiService.getApiClient();
    const removalStatus = await api.djepControllerRemoveIntegration();
    if (removalStatus.status === 'removed') {
      setIsIntegrated(false);
    }
  };

  useEffect(() => {
    if (events.length) {
      buildInitialTree();
    }
  }, [events]);

  useEffect(() => {
    setBtnDisabled(selectedTracks.length === 0);
  }, [selectedTracks]);

  // Check integration
  useEffect(() => {
    checkIntegration();
  }, []);

  return (
    <div className="djep-path">
      <div className={buildStyle.buildHeader}>
        <div>Import From DJ Event Planner</div>
      </div>
      {checkingIntegration ? (
        <IonSpinner>
          <IonLabel>Checking DJEP Integration...</IonLabel>
        </IonSpinner>
      ) : !isIntegrated ? (
        <IonGrid>
          <IonRow>
            <IonText>
              We need to connect your DJ Event Planner account. Please login to
              your DJ Event Planner account, then navigate to &#34;Setup&#34;,
              then to &#34;Integrations&#34;, and then &#34;Crate Hackers&#34;.
              Copy the API Key and Secret into the screen below. Don&#39;t have
              a DJ Event Planner Account? Create One.{' '}
              <span
                style={{
                  color: 'orange',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  openExternalLink(
                    'https://www.djeventplanner.com/signup.php?promotional_code=cratehackers2025',
                  );
                }}
              >
                Click here
              </span>{' '}
              to sign up today.
            </IonText>
          </IonRow>
          <IonRow>
            <IonInput
              label="API Key"
              labelPlacement="floating"
              placeholder="API Key"
              autocomplete="off"
              value={apiKey}
              onIonInput={(e: any) => setApiKey(e.target.value)}
            />
          </IonRow>
          <IonRow>
            <IonInput
              label="Secret Key"
              labelPlacement="floating"
              placeholder="Secret Key"
              value={apiSecretKey}
              autocomplete="off"
              type="password"
              onIonInput={(e: any) => setApiSecretKey(e.target.value)}
            />
          </IonRow>
          <IonRow>
            <IonButton
              onClick={handleIntegrate}
              disabled={!apiKey || !apiSecretKey}
            >
              Connect
            </IonButton>
          </IonRow>
          <IonRow>
            {error && <IonText color="danger">Error: {error}</IonText>}
          </IonRow>
        </IonGrid>
      ) : (
        <>
          <IonRow>
            <IonCol size="4" push="8" class="ion-text-right">
              <IonButton fill="clear" onClick={handleRemoveIntegration}>
                Remove Account Connection
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow style={{ marginBottom: '1rem', alignItems: 'center' }}>
            <IonCol size="5">
              <IonInput
                type="date"
                value={startDate}
                label="Start Date"
                labelPlacement="stacked"
                onIonInput={(e: any) => setStartDate(e.target.value)}
              />
            </IonCol>
            <IonCol size="5" push=".5">
              <IonInput
                type="date"
                value={endDate}
                label="End Date"
                labelPlacement="stacked"
                onIonInput={(e: any) => setEndDate(e.target.value)}
              />
            </IonCol>
            <IonCol size="1" push="1" style={{ textAlign: 'center' }}>
              <IonButton expand="block" onClick={fetchEvents}>
                Update
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="5">
              {eventTree.length > 0 && (
                <TreeView
                  data={eventTree}
                  aria-label="DJEP Event Tree"
                  onLoadData={handleLoadData}
                  nodeRenderer={({
                    element,
                    isExpanded,
                    level,
                    getNodeProps,
                  }) => (
                    <div
                      {...getNodeProps()}
                      style={{ paddingLeft: 30 * level }}
                    >
                      {element.isBranch &&
                        (isExpanded ? (
                          <IonIcon icon={caretDownOutline} />
                        ) : (
                          <IonIcon icon={caretForwardOutline} />
                        ))}
                      {element.name}
                    </div>
                  )}
                  onNodeSelect={({ element }) => handleNodeSelected(element)}
                />
              )}
            </IonCol>
            <IonCol size="7">
              <div
                className="ag-theme-alpine"
                style={{ height: 400, width: '100%' }}
              >
                <AgGridReact
                  className={'ag-theme-alpine-dark'}
                  rowData={selectedTracks}
                  columnDefs={[
                    { headerName: 'Title', field: 'title', flex: 1 },
                    { headerName: 'Artist', field: 'artist', flex: 1 },
                    { headerName: 'Comment', field: 'comment', flex: 1 },
                  ]}
                />
              </div>
            </IonCol>
          </IonRow>
          <BuildGenerateCrate
            buildCrateFn={buildCrate}
            isBtnDisabled={isBtnDisabled}
          />
        </>
      )}
    </div>
  );
};

export default DjepPath;
