/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the IntegrationDto interface.
 */
export function instanceOfIntegrationDto(value) {
    if (!('apiKey' in value) || value['apiKey'] === undefined)
        return false;
    if (!('apiSecretKey' in value) || value['apiSecretKey'] === undefined)
        return false;
    return true;
}
export function IntegrationDtoFromJSON(json) {
    return IntegrationDtoFromJSONTyped(json, false);
}
export function IntegrationDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'apiKey': json['api_key'],
        'apiSecretKey': json['api_secret_key'],
    };
}
export function IntegrationDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'api_key': value['apiKey'],
        'api_secret_key': value['apiSecretKey'],
    };
}
