/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the EventBasicDto interface.
 */
export function instanceOfEventBasicDto(value) {
    if (!('eventId' in value) || value['eventId'] === undefined)
        return false;
    if (!('eventDate' in value) || value['eventDate'] === undefined)
        return false;
    if (!('eventTitle' in value) || value['eventTitle'] === undefined)
        return false;
    if (!('songCount' in value) || value['songCount'] === undefined)
        return false;
    if (!('clientName' in value) || value['clientName'] === undefined)
        return false;
    if (!('endpointUrl' in value) || value['endpointUrl'] === undefined)
        return false;
    return true;
}
export function EventBasicDtoFromJSON(json) {
    return EventBasicDtoFromJSONTyped(json, false);
}
export function EventBasicDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'eventId': json['event_id'],
        'eventDate': json['event_date'],
        'eventTitle': json['event_title'],
        'songCount': json['song_count'],
        'clientName': json['client_name'],
        'endpointUrl': json['endpoint_url'],
    };
}
export function EventBasicDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'event_id': value['eventId'],
        'event_date': value['eventDate'],
        'event_title': value['eventTitle'],
        'song_count': value['songCount'],
        'client_name': value['clientName'],
        'endpoint_url': value['endpointUrl'],
    };
}
