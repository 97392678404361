import { IonIcon } from '@ionic/react';
import { chevronForwardCircleOutline, helpCircleOutline } from 'ionicons/icons';
import { NavLink } from 'react-router-dom';
import style from './build-selection.module.scss';
import isElectron from '../../../utils/isElectron';

export default function BuildSelection() {
  // Defined platform option will only show the path on that platform.
  const buildCratePaths = [
    { display: 'Home', link: 'home' },
    { display: 'Build from a Track', link: 'track' },
    { display: 'Import from Spotify', link: 'spotify' },
    { display: 'Import from Text', link: 'text' },
    { display: 'Import a File', link: 'file' },
    { display: 'Import from Serato', link: 'serato', platform: 'electron' },
    // { display: 'Start from a Genre', link: 'genre' },
    { display: 'Import from SMPL', link: 'smpl' },
    { display: 'Import from DJEP', link: 'djep' },
  ];
  return (
    <>
      {buildCratePaths
        // Disable serato path on web
        .filter((option) => !(option.platform === 'electron' && !isElectron()))
        .map((option, index) => (
          <NavLink
            to={`/my-crates/${option.link}`}
            activeClassName={style['crate-option-active']}
            className={`${style['crate-option']}`}
            key={index}
          >
            <div className={style['crate-option-text']}>{option.display}</div>
            <IonIcon
              className={style['crate-option-icon']}
              icon={
                option.link === 'home'
                  ? helpCircleOutline
                  : chevronForwardCircleOutline
              }
            />
          </NavLink>
        ))}
    </>
  );
}
