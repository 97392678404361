// Map to store active listeners for each channel
const channelListeners: Map<string, (event: any, ...args: any[]) => void> =
  new Map();

export const run = (channel: string, data?: any): void => {
  try {
    window.api.send(channel, data);
  } catch (error) {
    console.error('Post message failed:', error);
  }
};

export const on = (
  channel: string,
  listener: (event: any, ...args: any[]) => void,
): void => {
  // Remove existing listener for this channel if it exists
  if (channelListeners.has(channel)) {
    console.log('Removing existing "on" listener for channel:', channel);
    removeListener(channel);
  }

  // Store and add the new listener
  channelListeners.set(channel, listener);
  window.api?.on(channel, listener);
};

export const once = (
  channel: string,
  listener: (event: any, ...args: any[]) => void,
): void => {
  // For 'once' events, we'll create a wrapper that removes itself from our map
  const wrappedListener = (event: any, ...args: any[]) => {
    // Remove from our tracking map after execution
    channelListeners.delete(channel);
    // Execute original listener
    listener(event, ...args);
  };

  // Remove existing listener if it exists
  if (channelListeners.has(channel)) {
    console.log('Removing existing "once" listener for channel:', channel);
    removeListener(channel);
  }

  // Store and add the new wrapped listener
  channelListeners.set(channel, wrappedListener);
  window.api?.once(channel, wrappedListener);
};

// Optional: Add a function to remove a specific channel listener
export const removeListener = (channel: string): void => {
  const existingListener = channelListeners.get(channel);
  if (existingListener) {
    console.log('Removing listener for channel:', channel);
    window.api?.removeListener(channel, existingListener);
    channelListeners.delete(channel);
  }
};
