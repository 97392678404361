import { TokenData } from '../store/slices/token-state.slice';
import * as Sentry from '@sentry/react';

export const isTokenValid = (token: TokenData): boolean => {
  if (token.accessToken === null) {
    // console.error('Token null');
    return false;
  }

  try {
    const base64Url = token.accessToken.split('.')[1]; // payload
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payloadJson = atob(base64);
    const payload = JSON.parse(payloadJson);
    const currentTime = Date.now() / 1000;

    if (payload.exp && payload.exp > currentTime) {
      return !isTokenExpired(token);
    }

    if (payload.email) {
      Sentry.setUser({ email: payload.email });
    }

    console.log('Token expired');
    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const isTokenExpired = (token: TokenData): boolean => {
  if (token.expiration === null) return false;
  return Number(token.expiration) < Date.now();
};
