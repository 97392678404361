/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequestListFolderDtoFromJSON, RequestListFolderDtoToJSON, } from './RequestListFolderDto';
/**
 * Check if a given object implements the EventDetailDto interface.
 */
export function instanceOfEventDetailDto(value) {
    if (!('success' in value) || value['success'] === undefined)
        return false;
    if (!('domain' in value) || value['domain'] === undefined)
        return false;
    if (!('folders' in value) || value['folders'] === undefined)
        return false;
    return true;
}
export function EventDetailDtoFromJSON(json) {
    return EventDetailDtoFromJSONTyped(json, false);
}
export function EventDetailDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'success': json['success'],
        'domain': json['domain'],
        'folders': (json['folders'].map(RequestListFolderDtoFromJSON)),
    };
}
export function EventDetailDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'success': value['success'],
        'domain': value['domain'],
        'folders': (value['folders'].map(RequestListFolderDtoToJSON)),
    };
}
