/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the IntegrationStatusDto interface.
 */
export function instanceOfIntegrationStatusDto(value) {
    if (!('status' in value) || value['status'] === undefined)
        return false;
    return true;
}
export function IntegrationStatusDtoFromJSON(json) {
    return IntegrationStatusDtoFromJSONTyped(json, false);
}
export function IntegrationStatusDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'status': json['status'],
    };
}
export function IntegrationStatusDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'status': value['status'],
    };
}
