/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AudioFeaturesDtoFromJSON, AudioFeaturesDtoToJSON, } from './AudioFeaturesDto';
/**
 * Check if a given object implements the SimpleTrackDTO interface.
 */
export function instanceOfSimpleTrackDTO(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('artist' in value) || value['artist'] === undefined)
        return false;
    if (!('previewUrl' in value) || value['previewUrl'] === undefined)
        return false;
    if (!('popularity' in value) || value['popularity'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('artwork' in value) || value['artwork'] === undefined)
        return false;
    if (!('audioFeatures' in value) || value['audioFeatures'] === undefined)
        return false;
    return true;
}
export function SimpleTrackDTOFromJSON(json) {
    return SimpleTrackDTOFromJSONTyped(json, false);
}
export function SimpleTrackDTOFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'artist': json['artist'],
        'previewUrl': json['previewUrl'],
        'popularity': json['popularity'],
        'id': json['id'],
        'artwork': json['artwork'],
        'audioFeatures': AudioFeaturesDtoFromJSON(json['audioFeatures']),
    };
}
export function SimpleTrackDTOToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'artist': value['artist'],
        'previewUrl': value['previewUrl'],
        'popularity': value['popularity'],
        'id': value['id'],
        'artwork': value['artwork'],
        'audioFeatures': AudioFeaturesDtoToJSON(value['audioFeatures']),
    };
}
