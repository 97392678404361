// drive-state.slice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DriveInfo {
  path: string;
  label: string;
}

interface DriveStateSlice {
  connectedDrives: DriveInfo[];
}

const initialState: DriveStateSlice = {
  connectedDrives: [],
};

export const DriveStateSlice = createSlice({
  name: 'driveState',
  initialState,
  reducers: {
    setConnectedDrives: (state, action: PayloadAction<DriveInfo[]>) => {
      state.connectedDrives = action.payload;
    },
  },
});

export const { setConnectedDrives } = DriveStateSlice.actions;
export default DriveStateSlice.reducer;
