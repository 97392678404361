/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ImageDto interface.
 */
export function instanceOfImageDto(value) {
    if (!('url' in value) || value['url'] === undefined)
        return false;
    if (!('height' in value) || value['height'] === undefined)
        return false;
    if (!('width' in value) || value['width'] === undefined)
        return false;
    return true;
}
export function ImageDtoFromJSON(json) {
    return ImageDtoFromJSONTyped(json, false);
}
export function ImageDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'url': json['url'],
        'height': json['height'],
        'width': json['width'],
    };
}
export function ImageDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'url': value['url'],
        'height': value['height'],
        'width': value['width'],
    };
}
