/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SimpleTrackDTOFromJSON, SimpleTrackDTOToJSON, } from './SimpleTrackDTO';
import { ImageDtoFromJSON, ImageDtoToJSON, } from './ImageDto';
/**
 * Check if a given object implements the CHChartDto interface.
 */
export function instanceOfCHChartDto(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('spotifyPlaylistID' in value) || value['spotifyPlaylistID'] === undefined)
        return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined)
        return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined)
        return false;
    if (!('modifiedDate' in value) || value['modifiedDate'] === undefined)
        return false;
    if (!('adminControlled' in value) || value['adminControlled'] === undefined)
        return false;
    if (!('category' in value) || value['category'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    return true;
}
export function CHChartDtoFromJSON(json) {
    return CHChartDtoFromJSONTyped(json, false);
}
export function CHChartDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'spotifyPlaylistID': json['spotifyPlaylistID'],
        'createdBy': json['CreatedBy'],
        'createdDate': json['CreatedDate'],
        'modifiedDate': json['ModifiedDate'],
        'adminControlled': json['adminControlled'],
        'category': json['category'],
        'id': json['_id'],
        'tracks': json['tracks'] == null ? undefined : (json['tracks'].map(SimpleTrackDTOFromJSON)),
        'coverImage': json['coverImage'] == null ? undefined : (json['coverImage'].map(ImageDtoFromJSON)),
    };
}
export function CHChartDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'spotifyPlaylistID': value['spotifyPlaylistID'],
        'CreatedBy': value['createdBy'],
        'CreatedDate': value['createdDate'],
        'ModifiedDate': value['modifiedDate'],
        'adminControlled': value['adminControlled'],
        'category': value['category'],
        '_id': value['id'],
        'tracks': value['tracks'] == null ? undefined : (value['tracks'].map(SimpleTrackDTOToJSON)),
        'coverImage': value['coverImage'] == null ? undefined : (value['coverImage'].map(ImageDtoToJSON)),
    };
}
