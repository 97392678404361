/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KeyInfoDtoFromJSON, KeyInfoDtoToJSON, } from './KeyInfoDto';
/**
 * Check if a given object implements the AudioFeaturesDto interface.
 */
export function instanceOfAudioFeaturesDto(value) {
    if (!('danceability' in value) || value['danceability'] === undefined)
        return false;
    if (!('energy' in value) || value['energy'] === undefined)
        return false;
    if (!('keyInfo' in value) || value['keyInfo'] === undefined)
        return false;
    if (!('loudness' in value) || value['loudness'] === undefined)
        return false;
    if (!('speechiness' in value) || value['speechiness'] === undefined)
        return false;
    if (!('acousticness' in value) || value['acousticness'] === undefined)
        return false;
    if (!('instrumentalness' in value) || value['instrumentalness'] === undefined)
        return false;
    if (!('liveness' in value) || value['liveness'] === undefined)
        return false;
    if (!('valence' in value) || value['valence'] === undefined)
        return false;
    if (!('tempo' in value) || value['tempo'] === undefined)
        return false;
    if (!('type' in value) || value['type'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('uri' in value) || value['uri'] === undefined)
        return false;
    if (!('trackHref' in value) || value['trackHref'] === undefined)
        return false;
    if (!('analysisUrl' in value) || value['analysisUrl'] === undefined)
        return false;
    if (!('durationMs' in value) || value['durationMs'] === undefined)
        return false;
    if (!('timeSignature' in value) || value['timeSignature'] === undefined)
        return false;
    return true;
}
export function AudioFeaturesDtoFromJSON(json) {
    return AudioFeaturesDtoFromJSONTyped(json, false);
}
export function AudioFeaturesDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'danceability': json['danceability'],
        'energy': json['energy'],
        'keyInfo': KeyInfoDtoFromJSON(json['keyInfo']),
        'loudness': json['loudness'],
        'speechiness': json['speechiness'],
        'acousticness': json['acousticness'],
        'instrumentalness': json['instrumentalness'],
        'liveness': json['liveness'],
        'valence': json['valence'],
        'tempo': json['tempo'],
        'type': json['type'],
        'id': json['id'],
        'uri': json['uri'],
        'trackHref': json['trackHref'],
        'analysisUrl': json['analysisUrl'],
        'durationMs': json['durationMs'],
        'timeSignature': json['timeSignature'],
    };
}
export function AudioFeaturesDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'danceability': value['danceability'],
        'energy': value['energy'],
        'keyInfo': KeyInfoDtoToJSON(value['keyInfo']),
        'loudness': value['loudness'],
        'speechiness': value['speechiness'],
        'acousticness': value['acousticness'],
        'instrumentalness': value['instrumentalness'],
        'liveness': value['liveness'],
        'valence': value['valence'],
        'tempo': value['tempo'],
        'type': value['type'],
        'id': value['id'],
        'uri': value['uri'],
        'trackHref': value['trackHref'],
        'analysisUrl': value['analysisUrl'],
        'durationMs': value['durationMs'],
        'timeSignature': value['timeSignature'],
    };
}
