import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonRange,
  IonRouterLink,
  IonRow,
  IonText,
  IonToast,
  IonLoading,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { CrateService } from '../../../services/Crate.service';
import { iCrate } from '../../../types/ICrate';
import { iHackers } from '../../../types/IHackers';
import { iTrack } from '../../../types/ITrack';
import { CategoriesInline } from '../../categories/Categories';
import { HackersInline } from '../../hackers/Hackers';
import {
  apple,
  beatsource,
  cloudCrates,
  deezer,
  flameLogo,
  soundcloud,
  spotifyFull,
  tidal,
} from '../../Icons';
import { Tracks } from '../../tracks/Tracks';
import '../Crates.scss';
import isElectron from '../../../utils/isElectron';
import { useSelector } from 'react-redux';
import {
  selectCrateEditable,
  selectSelectedCrate,
  setCrateEditable,
} from '../../../store/slices/crate.slice';
import {
  selectAllMatches,
  selectFirstMatches,
  selectSelectedMatches,
  selectShowMatcher,
  setShowMatcher,
} from '../../../store/slices/track-matcher.slice';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { run } from '../../../helper/electron';
import Export from './Export';
import MagicSorter from './MagicSorter';
import ApiService from '../../../services/Api.service';
import { Recommendations } from '../../tracks/Recommendations';
import { useDispatch } from '../../../store/hooks';
import SearchComponent from '../../tracks/SearchComponent';
import TrackMatcherControls from '../../tracks/TrackMatcherControls';
import { CrateControllerUpdateCrateRequest } from '@cratehackers/api-client';
import { camelCase } from 'lodash';

interface iCrateProps {
  id?: string;
  tracks?: iTrack[];
  withTracks?: boolean;
  crateData?: iCrate;
  sortingAvailable?: boolean;
}

interface ICloudCrateLink {
  name: string;
  link: string;
}

interface FilePathMap {
  [trackId: string]: string[];
}

interface Toast {
  id: number;
  message: string;
  success: boolean;
}

export const Crate: React.FC<iCrateProps> = ({
  id,
  withTracks = false,
  tracks = [],
  crateData,
  sortingAvailable = true,
}) => {
  /* General Properties */
  const [isLoading, setIsLoading] = useState(false);
  const editable = useSelector(selectCrateEditable);
  const reduxCrate = useSelector(selectSelectedCrate);
  const [selectedFilePaths, setSelectedFilePaths] = useState<FilePathMap>({});
  const [crate, setCrate] = useState<iCrate>();

  const [hackers, setHackers] = useState<iHackers>({ hackers: [] });
  const [trackList, setTrackList] = useState<{ tracks: iTrack[] }>({
    tracks: tracks,
  });

  /* Sorting */
  const [energySort, setEnergySort] = useState({ lower: 40, upper: 100 });
  const [popularSort, setPopularSort] = useState({ lower: 60, upper: 100 });
  const [moodSort, setMoodSort] = useState({ lower: 40, upper: 100 });
  const [danceSort, setDanceSort] = useState({ lower: 60, upper: 100 });
  const [sortAvailable, setSortAvailable] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState<boolean>(false);
  const [filterAvailable, setFilterAvailable] = useState(false);
  const [filteredTracks, setFilteredTracks] = useState<{ tracks: iTrack[] }>({
    tracks: tracks,
  });

  const matchExport = useRouteMatch('/crate/:id/export');

  /* Toasts and Notifications */
  const [toastQueue, setToastQueue] = useState<Toast[]>([]);
  const [currentToast, setCurrentToast] = useState<Toast | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const showTrackMatcher = useSelector(selectShowMatcher);
  const selectedMatchesMap = useSelector(selectSelectedMatches());
  const totalMatches = Object.values(selectedMatchesMap).reduce(
    (sum, paths) => sum + paths.length,
    0,
  );
  const [isSavingCrate, setIsSavingCrate] = useState<boolean>(false);

  const handleSortChange = (sortedTracks: iTrack[]) => {
    setFilteredTracks({ tracks: sortedTracks });
  };

  const mapToLowerCamelCase = (currentCrate: any) => {
    const updatedCrate: any = {};
    for (const key in currentCrate) {
      const camelCaseKey = camelCase(key);
      updatedCrate[camelCaseKey] = currentCrate[key];
    }

    return updatedCrate;
  };

  const toggleTrackExpansion = (index: number) => {
    setTrackList((currentTrackList) => {
      const newTracks = [...currentTrackList.tracks];
      newTracks[index] = {
        ...newTracks[index],
        expanded: !newTracks[index].expanded,
      };
      return { tracks: newTracks };
    });
  };

  const saveToMyCrates = () => {
    if (isSavingCrate) {
      return; // Do nothing, because it's actively saving.
    }

    setIsSavingCrate(true);
    if (crate) {
      const modCrate = { ...crate, Tracks: filteredTracks.tracks }; // create a copy with updated Tracks
      if (crate.Type === 'User') {
        const chAPI = ApiService.getApiClient();
        const updateCrateData = mapToLowerCamelCase(modCrate);
        updateCrateData.published = new Date(updateCrateData.published);
        const crateControllerUpdateCrateRequest: CrateControllerUpdateCrateRequest =
          {
            id: crate._id,
            updateCrateDto: updateCrateData,
          };
        chAPI
          .crateControllerUpdateCrate(crateControllerUpdateCrateRequest)
          .then((response) => {
            addToast('Crate successfully updated', true);
          })
          .catch((error) => {
            addToast(
              'Error updating crate in My Crates. Please try again later.',
              false,
            );
          })
          .finally(() => {
            setIsSavingCrate(false);
          });
      } else {
        CrateService.createMyCrate(modCrate).then((data) => {
          setIsSavingCrate(false);
          history.push('/my-crates');
        });
      }
    }
  };

  const filterTracks = () => {
    if (filterEnabled) {
      const filtered = trackList.tracks.filter((track) => {
        return (
          (!track.energy ||
            (track.energy >= energySort.lower &&
              track.energy <= energySort.upper)) &&
          (!track.valence ||
            (track.valence >= moodSort.lower &&
              track.valence <= moodSort.upper)) &&
          (!track.danceability ||
            (track.danceability >= danceSort.lower &&
              track.danceability <= danceSort.upper))
        );
      });
      setFilteredTracks({ tracks: filtered });
    } else {
      setFilteredTracks(trackList);
    }
  };

  useEffect(() => {
    setCrate(crateData);
  }, [crateData]);

  useEffect(() => {
    filterTracks();
  }, [filterEnabled]);

  useEffect(() => {
    trackList.tracks.map((track) => {
      if (track.BPM && track.Key_Camelot) {
        setSortAvailable(true);
      }
      if (
        track.energy ||
        track.danceability ||
        track.popularity ||
        track.valence
      ) {
        setFilterAvailable(true);
      }
    });
  }, [trackList]);

  useEffect(() => {
    setFilteredTracks(trackList);
  }, [trackList]);

  useEffect(() => {
    filterTracks();
  }, [moodSort, danceSort, energySort, popularSort]);

  const addToast = (message: string, success: boolean) => {
    const newToast: Toast = {
      id: Date.now() + Math.random(),
      message,
      success,
    };
    setToastQueue((prevQueue) => [...prevQueue, newToast]);
  };

  useEffect(() => {
    if (!currentToast && toastQueue.length > 0) {
      setCurrentToast(toastQueue[0]);
      setToastQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [toastQueue, currentToast]);

  const handleDismissToast = () => {
    setCurrentToast(null);
  };

  useEffect(() => {
    if (currentToast) {
      const timer = setTimeout(() => {
        handleDismissToast();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [currentToast]);

  const handleFileSelectionsChange = (filePaths: FilePathMap) => {
    setSelectedFilePaths(filePaths);
    if (isElectron()) {
      run('crates/save-track-file-paths', {
        crate_id: id,
        filePaths: filePaths,
      });
    }
  };

  useEffect(() => {
    if (id === 'myCratesDraft') {
      if (reduxCrate) {
        setCrate(reduxCrate);
        if (reduxCrate.Hacker) {
          setHackers({
            hackers: reduxCrate.Hacker.map((hacker) => ({ name: hacker.name })),
          });
        }
        if (withTracks && reduxCrate.Tracks) {
          // Check if 'reduxCrate.Tracks' is an array
          if (Array.isArray(reduxCrate.Tracks)) {
            setTrackList({ tracks: reduxCrate.Tracks as iTrack[] });
          }
          // Else, check if 'reduxCrate.Tracks' is an object with property 'tracks'
          else if (
            typeof reduxCrate.Tracks === 'object' &&
            'tracks' in reduxCrate.Tracks
          ) {
            setTrackList({
              tracks: (reduxCrate.Tracks as { tracks: iTrack[] }).tracks,
            });
          }

          dispatch(setCrateEditable(true));
        }
      }
    } else if (id) {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      CrateService.fetchCrate(
        id,
        (data) => {
          if (data) {
            setCrate(data);
            if (data.Hacker) {
              setHackers({
                hackers: data.Hacker.map((hacker) => ({ name: hacker.name })),
              });
            }
            if (withTracks && data.Tracks) {
              setTrackList({ tracks: data.Tracks });
            }
            if (data.Type === 'User') {
              dispatch(setCrateEditable(true));
            } else {
              dispatch(setCrateEditable(false));
            }
          }
          setIsLoading(false);
        },
        history,
      );
    }
  }, [id, withTracks, reduxCrate]);

  const mapToITrack = (trackSearchDto: any): iTrack => {
    // TODO: Marked as any because there are openapi errors
    console.log('mapToITrack:', trackSearchDto);
    return {
      ID: trackSearchDto.uuid,
      Artist: trackSearchDto.artist?.name,
      Title: trackSearchDto.title || '',
      AlbumCover: trackSearchDto.album?.picture,
      BPM: Math.round(trackSearchDto.bpm) || -1,
      energy: Math.round(trackSearchDto.energy * 100) || undefined,
      danceability: Math.round(trackSearchDto.danceability * 100) || undefined,
      valence: Math.round(trackSearchDto.valence * 100) || undefined,
      popularity: trackSearchDto.popularity || undefined,
      Key_Camelot: trackSearchDto.key || '',
    };
  };

  const addTrackHandler = (trackId: string) => {
    const api = ApiService.getApiClient();
    api
      .trackControllerGetTrack({
        id: trackId,
      })
      .then((track) => {
        const mappedTrack = mapToITrack(track);
        setTrackList((currentTrackList) => ({
          tracks: [...currentTrackList.tracks, mappedTrack],
        }));

        console.log(trackList);
      });
  };

  const generateCloudCrateMap = () => {
    const cloudCrateMap: ICloudCrateLink[] = [];

    if (crate?.StreamDeezer) {
      cloudCrateMap.push({
        name: 'deezer',
        link: crate.StreamDeezer,
      });
    }

    if (crate?.StreamTidal) {
      cloudCrateMap.push({
        name: 'tidal',
        link: crate.StreamTidal,
      });
    }

    if (crate?.StreamSoundcloud) {
      cloudCrateMap.push({
        name: 'soundcloud',
        link: crate.StreamSoundcloud,
      });
    }

    if (crate?.StreamAppleMusic) {
      cloudCrateMap.push({
        name: 'apple',
        link: crate.StreamAppleMusic,
      });
    }

    if (crate?.StreamBeatsource) {
      cloudCrateMap.push({
        name: 'beatsource',
        link: crate.StreamBeatsource,
      });
    }

    if (crate?.StreamSpotify) {
      cloudCrateMap.push({
        name: 'spotify',
        link: crate.StreamSpotify,
      });
    }
    return cloudCrateMap;
  };

  return (
    <>
      <IonLoading
        isOpen={isSavingCrate}
        spinner="crescent"
        style={{ '--background': 'none' }}
      />
      {currentToast && (
        <IonToast
          isOpen={true}
          onDidDismiss={handleDismissToast}
          message={currentToast.message}
          duration={3000}
          color={currentToast.success ? 'success' : 'danger'}
          position="top"
        />
      )}
      <IonGrid data-id={crate?._id} className={`crate-card`}>
        <IonRow>
          <IonCol size="12" sizeMd="6" sizeLg="2">
            {crateData?.Image ? (
              <IonImg alt={crateData.Title} src={crateData?.Image} />
            ) : crate?.Image ? (
              <IonImg
                alt={crate?.Title}
                src={'https:' + (crate?.Image || '').replace(/^https?:/, '')}
              />
            ) : (
              <IonImg alt={crate?.Title} src={flameLogo} />
            )}
          </IonCol>
          <IonCol>
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="12" size-lg="5">
                  <IonItem className="categories-container">
                    <IonLabel className="categories">
                      <CategoriesInline
                        categories={
                          crateData?.Category
                            ? crateData.Category
                            : crate?.Category
                        }
                      />
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel className="title">
                      <h1>
                        {crateData?.Title ? crateData.Title : crate?.Title}
                      </h1>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel className="hackers">
                      <HackersInline hackers={hackers.hackers} />
                    </IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="12" size-md="12" size-lg="6">
                  <IonGrid className="cloud-crate-grid">
                    <IonRow className="ion-align-items-center">
                      {(crate?.StreamDeezer ||
                        crate?.StreamTidal ||
                        crate?.StreamBeatsource ||
                        crate?.StreamSpotify ||
                        crate?.StreamSoundcloud ||
                        crate?.StreamAppleMusic) && (
                        <IonCol
                          size="12"
                          sizeLg="auto"
                          className="ion-justify-content-center"
                        >
                          <IonItem className="cloud-crates-icon">
                            <IonIcon icon={cloudCrates}></IonIcon>
                          </IonItem>
                        </IonCol>
                      )}
                      {crate?.StreamSpotify && (
                        <IonCol>
                          <IonRouterLink
                            className={'cloud-crate-item'}
                            onClick={() =>
                              isElectron()
                                ? run('openExternalLink', crate.StreamSpotify)
                                : window.open(crate.StreamSpotify, '_blank')
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <IonIcon icon={spotifyFull}></IonIcon>
                          </IonRouterLink>
                        </IonCol>
                      )}
                      {crate?.StreamAppleMusic && (
                        <IonCol>
                          <IonRouterLink
                            className={'cloud-crate-item'}
                            onClick={() =>
                              isElectron()
                                ? run(
                                    'openExternalLink',
                                    crate.StreamAppleMusic,
                                  )
                                : window.open(crate.StreamAppleMusic, '_blank')
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <IonIcon icon={apple}></IonIcon>
                          </IonRouterLink>
                        </IonCol>
                      )}
                      {crate?.StreamSoundcloud && (
                        <IonCol>
                          <IonRouterLink
                            className={'cloud-crate-item'}
                            onClick={() =>
                              isElectron()
                                ? run(
                                    'openExternalLink',
                                    crate.StreamSoundcloud,
                                  )
                                : window.open(crate.StreamSoundcloud, '_blank')
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <IonIcon icon={soundcloud}></IonIcon>
                          </IonRouterLink>
                        </IonCol>
                      )}
                      {crate?.StreamBeatsource && (
                        <IonCol>
                          <IonRouterLink
                            className={'cloud-crate-item'}
                            onClick={() =>
                              isElectron()
                                ? run(
                                    'openExternalLink',
                                    crate.StreamBeatsource,
                                  )
                                : window.open(crate.StreamBeatsource, '_blank')
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <IonIcon icon={beatsource}></IonIcon>
                          </IonRouterLink>
                        </IonCol>
                      )}
                      {crate?.StreamDeezer && (
                        <IonCol>
                          <IonRouterLink
                            className={'cloud-crate-item'}
                            onClick={() =>
                              isElectron()
                                ? run('openExternalLink', crate.StreamDeezer)
                                : window.open(crate.StreamDeezer, '_blank')
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <IonIcon icon={deezer}></IonIcon>
                          </IonRouterLink>
                        </IonCol>
                      )}
                      {crate?.StreamTidal && (
                        <IonCol>
                          <IonRouterLink
                            className={'cloud-crate-item'}
                            onClick={() =>
                              isElectron()
                                ? run('openExternalLink', crate.StreamTidal)
                                : window.open(crate.StreamTidal, '_blank')
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <IonIcon icon={tidal}></IonIcon>
                          </IonRouterLink>
                        </IonCol>
                      )}
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
              <IonRow className="description">
                <IonCol size="12">
                  <IonItem>
                    <IonLabel class="ion-text-wrap">
                      {crate?.Description}
                    </IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid className={`actions-container`}>
        <IonRow className="crate-actions-row">
          <IonCol size="12" sizeMd="5" sizeLg="8">
            <TrackMatcherControls
              showTrackMatcher={showTrackMatcher}
              onToggleTrackMatcher={(e) =>
                dispatch(setShowMatcher(e.detail.checked))
              }
              onSelectFirstMatches={() => dispatch(selectFirstMatches())}
              onSelectAllMatches={() => dispatch(selectAllMatches())}
              totalMatches={totalMatches}
            />
          </IonCol>

          <IonCol size="12" sizeMd="7" sizeLg="4">
            <div className="ion-float-end action-buttons">
              <IonButton
                className="save-to-my-crates ion-text-capitalize"
                fill="outline"
                color="primary"
                onClick={saveToMyCrates}
                disabled={isSavingCrate}
              >
                <IonText
                  className="ion-text-capitalize ion-text-wrap"
                  color="dark"
                >
                  {crate && crate.Type === 'User'
                    ? `Update In My Crates`
                    : `Save To My Crates`}
                </IonText>
              </IonButton>

              {isElectron() && (
                <Export
                  crateName={crateData?.Title || crate?.Title || ''}
                  crateId={id}
                  tracks={selectedFilePaths}
                  trackInfo={filteredTracks.tracks}
                  hacker={
                    hackers?.hackers?.map((hacker) => hacker.name).join(', ') ||
                    ''
                  }
                  forceShowExport={!!matchExport}
                  cloudCrateList={generateCloudCrateMap()}
                />
              )}
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          {editable && (
            <IonGrid>
              <IonRow className="filter-slider-row">
                {filterAvailable && (
                  <>
                    <IonCol
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      size="1.5"
                    >
                      <IonCheckbox
                        checked={filterEnabled}
                        onIonChange={(e) => setFilterEnabled(e.detail.checked)}
                        style={{ paddingRight: '10px' }}
                      />
                      <IonLabel>Enable Filtering</IonLabel>
                    </IonCol>
                    <IonCol size="2" style={{ marginRight: '50px' }}>
                      <IonGrid>
                        <IonRow>
                          <IonRange
                            disabled={!filterEnabled}
                            dualKnobs={true}
                            value={energySort}
                            pin={true}
                            onIonInput={(event: any) =>
                              setEnergySort({
                                lower: event.detail.value.lower,
                                upper: event.detail.value.upper,
                              })
                            }
                          />
                        </IonRow>
                        <IonRow className="filter-slider-label">
                          <IonText>Energy</IonText>
                        </IonRow>
                      </IonGrid>
                    </IonCol>
                    <IonCol size="2" style={{ marginRight: '50px' }}>
                      <IonGrid>
                        <IonRow>
                          <IonRange
                            disabled={!filterEnabled}
                            dualKnobs={true}
                            value={moodSort}
                            pin={true}
                            onIonInput={(event: any) =>
                              setMoodSort({
                                lower: event.detail.value.lower,
                                upper: event.detail.value.upper,
                              })
                            }
                          />
                        </IonRow>
                        <IonRow className="filter-slider-label">
                          <IonText>Mood</IonText>
                        </IonRow>
                      </IonGrid>
                    </IonCol>
                    <IonCol size="2" style={{ marginRight: '50px' }}>
                      <IonGrid>
                        <IonRow>
                          <IonRange
                            disabled={!filterEnabled}
                            dualKnobs={true}
                            value={danceSort}
                            pin={true}
                            onIonInput={(event: any) =>
                              setDanceSort({
                                lower: event.detail.value.lower,
                                upper: event.detail.value.upper,
                              })
                            }
                          />
                        </IonRow>
                        <IonRow className="filter-slider-label">
                          <IonText>Danceability</IonText>
                        </IonRow>
                      </IonGrid>
                    </IonCol>
                    {/*<IonCol size="2" style={{ marginRight: '50px' }}>*/}
                    {/*  <IonGrid>*/}
                    {/*    <IonRow>*/}
                    {/*      <IonRange*/}
                    {/*        disabled={!filterEnabled}*/}
                    {/*        dualKnobs={true}*/}
                    {/*        value={popularSort}*/}
                    {/*        pin={true}*/}
                    {/*        onIonInput={(event: any) =>*/}
                    {/*          setPopularSort({*/}
                    {/*            lower: event.detail.value.lower,*/}
                    {/*            upper: event.detail.value.upper,*/}
                    {/*          })*/}
                    {/*        }*/}
                    {/*      />*/}
                    {/*    </IonRow>*/}
                    {/*    <IonRow className="filter-slider-label">*/}
                    {/*      <IonText>Popularity</IonText>*/}
                    {/*    </IonRow>*/}
                    {/*  </IonGrid>*/}
                    {/*</IonCol>*/}

                    <IonCol
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      {sortAvailable && (
                        <MagicSorter
                          tracks={filteredTracks.tracks}
                          linkedTracks={[]}
                          onSortChange={handleSortChange}
                        />
                      )}
                    </IonCol>
                  </>
                )}
              </IonRow>
            </IonGrid>
          )}
        </IonRow>
      </IonGrid>
      {withTracks && (
        <Tracks
          editable={editable}
          showTrackMatcher={showTrackMatcher}
          tracks={filteredTracks?.tracks}
          onSelectionChange={handleFileSelectionsChange}
          toggleTrackExpansion={toggleTrackExpansion}
        />
      )}
      {editable && <SearchComponent handleClick={addTrackHandler} />}
      {filteredTracks?.tracks?.length > 0 &&
        filteredTracks?.tracks?.at(-1) &&
        editable && (
          <Recommendations
            ID={filteredTracks?.tracks?.at(-1)?.SpotifyID || ''}
            Artist={filteredTracks?.tracks?.at(-1)?.Artist || ''}
            Title={filteredTracks?.tracks?.at(-1)?.Title || ''}
            Key_Camelot={
              filteredTracks?.tracks?.at(-1)?.Key_Camelot || undefined
            }
            keyMatches={filteredTracks?.tracks?.at(-1)?.keyMatches || undefined}
            showAddTrack={editable}
            addTrackHandler={addTrackHandler}
          />
        )}
    </>
  );
};
