import React, { useEffect, useState } from 'react';
import { IonButton, IonCheckbox, IonLabel, IonText } from '@ionic/react';

import './Preferences.scss';

// TODO: Get this from the API when ready
const poolNames = [
  'Barbangerz',
  'BPM Supreme',
  'Club Killers',
  'Country DJ Pool',
  'Crate Gang',
  'DJcity',
  'Digital DJ Pool',
  'Digital Music Pool',
  'Direct Music Service',
  'Doing The Damage',
  'Headliner Music Club',
  'Heavy Hits',
  'iDJPool',
  'Late Night Record Pool',
  'Latino Music Pool',
  'MP3 For DJs',
  'MyMP3Pool',
  'Radio DJ Pool',
  'Urban DJ Pool',
  'XMiX',
  'XtendaMix',
  'ZIPDJ',
];

const PreferencePage = () => {
  const [selectedPools, setSelectedPools] = useState<string[]>([]);

  const updateLocalStorage = (newSelectedPools: string[]) => {
    localStorage.setItem('selectedPools', JSON.stringify(newSelectedPools));
  };

  useEffect(() => {
    const storedPools = JSON.parse(
      localStorage.getItem('selectedPools') as string,
    );
    setSelectedPools(storedPools || poolNames);
  }, []);

  const handleCheck = (pool: string, isChecked: boolean) => {
    if (isChecked) {
      const newSelectedPools = [...selectedPools, pool];
      setSelectedPools(newSelectedPools);
      updateLocalStorage(newSelectedPools);
    } else {
      const newSelectedPools = selectedPools.filter(
        (selectedPool) => selectedPool !== pool,
      );
      setSelectedPools(newSelectedPools);
      updateLocalStorage(newSelectedPools);
    }
  };

  const handleSelectAll = () => {
    setSelectedPools(poolNames);
    updateLocalStorage(poolNames);
  };

  const handleUnselectAll = () => {
    setSelectedPools([]);
    updateLocalStorage([]);
  };

  return (
    <>
      <IonText>
        <h1>Select Pools To Use</h1>
      </IonText>
      <div id="pool-selection-buttons">
        <IonButton
          disabled={selectedPools.length === poolNames.length}
          fill="outline"
          onClick={handleSelectAll}
        >
          Select All
        </IonButton>
        <IonButton
          disabled={selectedPools.length === 0}
          fill="outline"
          onClick={handleUnselectAll}
        >
          Unselect All
        </IonButton>
      </div>
      {poolNames.map((pool) => (
        <div className="pool-selection-item" key={pool}>
          <IonCheckbox
            checked={selectedPools.includes(pool)}
            onIonChange={(e) => handleCheck(pool, e.detail.checked)}
          />
          <IonLabel className="pool-checkbox-label">{pool}</IonLabel>
        </div>
      ))}

      {/*<DJEPPreferences />*/}
    </>
  );
};

export default PreferencePage;
