import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const djepTitleFormatSlice = createSlice({
  name: 'format',
  initialState: {
    customFormat: localStorage.getItem('eventFormat') || '',
  },
  reducers: {
    updateFormat: (state, action) => {
      state.customFormat = action.payload;
    },
  },
});

export const selectDjepTitleFormat = (state: RootState) =>
  state.djepTitleFormat.customFormat;

export const { updateFormat } = djepTitleFormatSlice.actions;
export default djepTitleFormatSlice;
